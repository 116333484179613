const INFORMATION_ROUTER = [
  {
    path: 'information',
    component: () => import('@/views/information/index.vue')
  },
  {
    path: 'information/detail',
    component: () => import('@/views/information/detail.vue')
  },
]

export default INFORMATION_ROUTER

import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/views/Tabs.vue'
import home from './home'
import information from './information'
import discover from './discover'
import member from './member'
import login from './login'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      ...home,
      ...information,
      ...discover,
      ...member,
    ]
  },
  ...login,
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export const skipRouter = (path = "/tabs/home", query = {}, typeId?) => {
  if (typeId === undefined && path.includes('login')) {
    typeId = 2;
  } else {
    typeId = 1;
  }
  const type = {
    1: 'push',
    2: 'replace'
  }
  router[type[typeId]]({
    path,
    query
  });
}

export default router


const HOME_ROUTER = [
  {
    path: 'home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: 'home/qualifications',
    component: () => import('@/views/home/qualifications.vue')
  },
]

export default HOME_ROUTER

const MEMBER_ROUTER = [
  {
    path: 'member',
    component: () => import('@/views/member/index.vue')
  },
  {
    path: 'member/feedback',
    component: () => import('@/views/member/feedback.vue')
  },
  {
    path: 'member/feedback-record',
    component: () => import('@/views/member/feedback-record.vue')
  },
  {
    path: 'member/data-dashboard',
    component: () => import('@/views/member/data-dashboard.vue')
  },
  {
    path: 'member/setting',
    component: () => import('@/views/member/setting.vue')
  },
  {
    path: 'member/partner-policy',
    component: () => import('@/views/member/partner-policy.vue')
  },
  {
    path: 'member/privacy-agreement',
    component: () => import('@/views/member/privacy-agreement.vue')
  },
  {
    path: 'member/use-clause',
    component: () => import('@/views/member/use-clause.vue')
  },
  {
    path: 'member/contact-us',
    component: () => import('@/views/member/contact-us.vue')
  },
  {
    path: 'member/supplier-info',
    component: () => import('@/views/member/supplier-info.vue')
  },
  {
    path: 'member/personnel-info',
    component: () => import('@/views/member/personnel-info.vue')
  },
  {
    path: 'member/tender-manage',
    component: () => import('@/views/member/tender-manage.vue')
  },
  {
    path: 'member/tender-info',
    component: () => import('@/views/member/tender-info.vue')
  },
  {
    path: 'member/tendernotice-info',
    component: () => import('@/views/member/tendernotice-info.vue')
  },
]

export default MEMBER_ROUTER

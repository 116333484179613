
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { skipRouter } from "@/router";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const tabConfig = [
      {
        id: "home",
        label: "首页",
        icon: "home",
        href: "/tabs/home",
      },
      {
        id: "information",
        label: "资讯",
        icon: "information",
        href: "/tabs/information",
      },
      {
        id: "discover",
        label: "发现",
        icon: "discover",
        href: "/tabs/discover",
      },
      {
        id: "member",
        label: "我的",
        icon: "member",
        href: "/tabs/member",
      },
    ];

    const currentPath = ref("");

    const isShow: any = ref(true);
    const toggle = (path: string) => {
      const status = path.includes("/tabs") && path.split("/").length <= 3;
      isShow.value = status;
      currentPath.value = path;
    };

    onMounted(() => {
      const { path } = useRoute();
      toggle(path);
    });

    onBeforeRouteUpdate(({ path }) => {
      toggle(path);
    });

    return {
      currentPath,
      isShow,
      tabConfig,
      skipRouter,
    };
  },
};

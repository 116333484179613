const DISCOVER_ROUTER = [
  {
    path: 'discover',
    component: () => import('@/views/discover/index.vue')
  },
  {
    path: 'discover/recruitDetail',
    component: () => import('@/views/discover/recruitDetail.vue')
  },
  {
    path: 'discover/tenderDetail',
    component: () => import('@/views/discover/tenderDetail.vue')
  },
]

export default DISCOVER_ROUTER

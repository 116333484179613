import { createStore } from "vuex";
import user from "./user"

export default createStore({
  modules: { user },
});

// import { createStore } from "vuex";

// const files = require.context("./", false, /\.js$/);
// const modules: any = {};
// files.keys().forEach((key) => {
//   modules[key.replace(/\.\/|\.js/g, "")] = files(key).default;
// });

// export default createStore({
//   modules,
// });


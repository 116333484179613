const LOGIN_ROUTER = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/login/selected-identity',
    component: () => import('@/views/login/selected-identity.vue')
  },
]

export default LOGIN_ROUTER

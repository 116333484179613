const local = {
  set: (key: any, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get: (key: any) => {
    try {
      return JSON.parse(localStorage.getItem(key) as string)
    }
    catch(err) {
      return localStorage.getItem(key) as string;
    }
  },
  remove: (key: any) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
}
const session = {
  set: (key: any, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  get: (key: any) => {
    try {
      return JSON.parse(sessionStorage.getItem(key) as string)
    }
    catch(err) {
      return sessionStorage.getItem(key) as string;
    }
  },
  remove: (key: any) => {
    sessionStorage.removeItem(key)
  },
  clear: () => {
    sessionStorage.clear()
  },
}

export { local, session }


// store.set(key, val)  //存储 key 的值为 val；
// store.get(key)  //获取 key 的值；
// store.remove(key) //移除 key 的记录；
// store.clear()  //清空存储；
// store.getAll() //返回所有存储；
// store.forEach() //遍历所有存储。
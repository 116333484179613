import { local } from "@/utils/storage.service";

const state = () => ({
  userName: '',
  userId: "",
  userType: "",
  mobile: "",
  workerId: "",
  supplierId: "",
})

const getters = {
  userInfo: (state: any) => ({
    userName: state.userName,
    userId: state.userId,
    userType: state.userType,
    mobile: state.mobile,
    workerId: state.workerId,
    supplierId: state.supplierId,
  }),
  // userName: (state: any) => state.userName,
  // userId: (state: any) => state.userId,
  // userType: (state: any) => state.userType,
  // mobile: (state: any) => state.mobile,
  // workerId: (state: any) => state.workerId,
  // supplierId: (state: any) => state.supplierId,
}

const actions = {
  setUserInfo({ commit }: any, payload: any) {
    commit('SET_USER_INFO', payload)
  },
  // setUserName({ commit }: any, payload: any) {
  //   commit('SET_USER_NAME', payload)
  // },
  // setUserId({ commit }: any, payload: any) {
  //   commit('SET_USER_ID', payload)
  // },
  // setUserType({ commit }: any, payload: any) {
  //   commit('SET_USER_TYPE', payload)
  // },
  // setMobile({ commit }: any, payload: any) {
  //   commit('SET_MOBILE', payload)
  // },
  // setWorkerId({ commit }: any, payload: any) {
  //   commit('SET_WORKER_ID', payload)
  // },
  // setSupplierId({ commit }: any, payload: any) {
  //   commit('SET_SUPPLIER_ID', payload)
  // },
}

const mutations = {
  SET_USER_INFO(state: any, payload: any) {
    Object.entries(payload).forEach(([ key, value ]: any) => {
      state[key] = value;
    });
    local.set("loginUser", payload);
  },
  // SET_USER_NAME(state: any, payload: any) {
  //   state.userName = payload
  // },
  // SET_USER_ID(state: any, payload: any) {
  //   state.userId = payload
  // },
  // SET_USER_TYPE(state: any, payload: any) {
  //   state.userType = payload
  // },
  // SET_MOBILE(state: any, payload: any) {
  //   state.mobile = payload
  // },
  // SET_WORKER_ID(state: any, payload: any) {
  //   state.workerId = payload
  // },
  // SET_SUPPLIER_ID(state: any, payload: any) {
  //   state.supplierId = payload
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a17cda5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _withDirectives(_createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabConfig, (tab) => {
                return (_openBlock(), _createBlock(_component_ion_tab_button, {
                  key: tab.id,
                  tab: tab.id,
                  class: "tabs-btn",
                  onClick: ($event: any) => ($setup.skipRouter(tab.href, {}, 2))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "tabs-icon",
                      src: 
                require(`@/assets/images/tabs/${tab.icon}${
                  $setup.currentPath.includes(tab.href) ? '-active' : ''
                }.png`)
              
                    }, null, 8, _hoisted_1),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          class: _normalizeClass($setup.currentPath.includes(tab.href) ? 'checked' : '')
                        }, _toDisplayString(tab.label), 3)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["tab", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 512), [
            [_vShow, $setup.isShow]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}